@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/54c76208542a99cd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/54c76208542a99cd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/ac0230b4090118df-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/62e48611196b08b2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/941396b781615e84-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/0066078b55585ece-s.p.ttf) format('truetype');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__montserrat_601cc1';
src: url(/_next/static/media/0066078b55585ece-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__montserrat_Fallback_601cc1';src: local("Arial");ascent-override: 84.97%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.92%
}.__className_601cc1 {font-family: '__montserrat_601cc1', '__montserrat_Fallback_601cc1'
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/e904ed9711bacfd2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/e904ed9711bacfd2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/6f68d1f6ce1171c9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/4338f11e210530dc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/1f50fb400295abf7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/3ccc4265d311b011-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__galanoGrotesque_d569cf';
src: url(/_next/static/media/3ccc4265d311b011-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__galanoGrotesque_Fallback_d569cf';src: local("Arial");ascent-override: 89.84%;descent-override: 31.44%;line-gap-override: 0.00%;size-adjust: 111.31%
}.__className_d569cf {font-family: '__galanoGrotesque_d569cf', '__galanoGrotesque_Fallback_d569cf'
}

